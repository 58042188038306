html {
    height:100%;
    box-sizing: border-box;
}

body {
    height:100%;
    background-color: #fff;
    color: #333;
    font: 1.2em / 1.5 Helvetica Neue, Helvetica, Arial, sans-serif;
    padding: 0;
    margin: 0;
}

* {
    box-sizing: inherit;
}

.App {
  text-align: center;
  padding-top: 30px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.wrapper {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.credits {
  text-align: left;
  padding: 1em 7em;
}

@media only screen and (max-width: 600px)  {
  .credits {
    padding: 1em 3em;
  }
}
